import * as React from "react"
import { Link, graphql } from "gatsby"
import { linkResolver } from '../utils/link_resolver'
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import Seo from '../components/seo'
import Footer from '../components/footer'
import Header from "../components/header"

const BlogPostPage = ({data}) => {
  const entry = data.prismicBlog

  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image={entry.data.image.url} pathname={linkResolver(entry.data)} />
      <Header logo='cuddon' />

      <div className="w-full bg-blue relative">
        <div className="absolute -inset-0 opacity-10">
          <img className="w-full h-full object-cover" src="/img/topography.svg" alt="" />  
        </div>
        <div className="w-full relative">
          <div className="flex-none md:flex justify-center items-center">
            <div className="w-full md:w-11/12 pl-12 pt-16 pb-16 pr-24">
              <h2 className="font-brand leading-none font-black text-5xl text-white text-center">
                {entry.data.heading}
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full py-12">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="flex-none md:flex justify-center items-start gap-24">
            <div className="w-full pt-12 md:pt-0">
              <div className="w-8/12 mx-auto pb-12">
                <GatsbyImage style={{ width: "100%", height: "100%", display: "block", objectFit: 'cover' }} image={entry.data.image.gatsbyImageData} alt={entry.data.image.alt ? entry.data.image.alt : ''} />
              </div>
              <div className="w-11/12 mx-auto font-sans max-w-none prose prose-lg prose-h2:font-brand prose-h3:font-brand leading-normal text-center">
                <PrismicRichText field={entry.data.text.richText} />
              </div>
              <div className="w-full mx-auto flex gap-6">
                <Link to="/contact/" className="rounded-lg text-xl font-sans font-bold bg-grey-e text-blue mt-12 py-3 px-6 inline-block">
                  Contact us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

export const query = graphql`
query($handle: String) {
  prismicBlog(uid: {eq: $handle}) {
    data {
      meta_description
      page_title
      heading
      image {
        alt
        gatsbyImageData
        url
      }
      text {
        richText
      }
    }
  }
}
`

export default BlogPostPage
